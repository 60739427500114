export const environment = {
  production: false,
  name: 'dev',
  api_url: 'https://api.pir.ru',
  apiV2_url: 'https://expov3.apiv2.pir.ru',
  award_api: 'https://dev.award.bestchefs.ru/api',
  exhibition_id: 'c0dbce47-d23a-11ea-9f01-000c29f15ff6',
  chefBreakfastThemeId: '36',
  chefBreakfast2023ThemeId: '21',
  chefBreakfast2022ThemeId: '13',
  awardThemeId: '6',
  ticketId: '138',
};
